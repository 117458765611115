// ***** Slider unused in last version *****



// ==============================================

//        SLIDER SLICK CALCUL

// ==============================================


// Slider unused in last version


$(document).ready(function(){

  //        Slider Slick
  // ==============================================

  var slider = $('#calcul-slider');
  var slide = $('.calcul-slide');

  var startSlide = 1;

  var sliderSettings = {
    // slide    : '.calcul-slide',
    dots     : false,
    arrows   : false,
    infinite : false,
    speed    : 600,
    draggable: false,
    accessibility: false,
    cssEase  : 'ease-in-out',
    initialSlide  : startSlide-1,
    adaptiveHeight : true,
  };

  // init -> set active link in nav
  $(slider).on('init', function (event, slick) {
    $('.nav-calcul__link[data-slide='+(startSlide)+']').addClass('is-active');
    $('.nav-calcul__wave').attr('data-position', startSlide);
  });


  if ($(slide).length  > 1) {
    $(slider).slick(sliderSettings);
  }


  // Slider navigation
  $('.nav-calcul__link').click(function(e) {
    e.preventDefault();

    var slideId = $(this).data('slide');
    $(slider).slick('slickGoTo', slideId - 1);
  });

  // Activate / desactivate nav link when slider change
  $(slider).on('beforeChange', function(event, slick, currentSlide, nextSlide){
    $('.nav-calcul__link[data-slide='+(currentSlide+1)+']').removeClass('is-active');
    $('.nav-calcul__link[data-slide='+(nextSlide+1)+']').addClass('is-active');
    $('.nav-calcul__wave').attr('data-position', (nextSlide+1));
  });


});
 