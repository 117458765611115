// ==============================================

//        TAX CALCULATOR

// ==============================================


$(document).ready(function(){


  //        Input fields
  // ==============================================

  $('.input-calculator').on('keyup', function() {
    $(this).val( format_number( $(this).val().replace(/[^\d.]/g, '') ) );

    var context = $(this).parents('form').data('context');
    (context == 'IFI') ? calculate_IFI() : calculate_IR();

  }).on('blur', function(){
  });


  function calculate_IFI() {
    var tax_before   = $('#form-IFI #tax-before').val().replace(/\s/g,'');
    var don_advice   = 0;
    var don_input    = $('#form-IFI #don-input-IFI').val().replace(/\s/g,'');
    var tax_after    = 0;

    // tax filled by the user
    if (tax_before) {
      don_advice = Math.round( tax_before * 1.33332 );
      if (don_advice > 66667) don_advice = 66667;
    }

    // don filled by the user
    if (don_input) {
      var don_discount = Math.round( don_input * 0.75 );    // 75% de don_input
      tax_after = tax_before - don_discount;                // IFI amount - (75% * don)
      if (tax_after < 0) tax_after = 0;
    }

    $('#form-IFI #don-advice').val( format_number( don_advice ) );
    $('#form-IFI #tax-after').val( format_number( tax_after ) );

    // update btn label and url with don amount
    var btn_label = 'Je fais un don IFI';
    var btn_href = $('#btn-don-IFI').data('base-url');    // get href base in data attribute
    if (don_input && don_input > 0) {
      btn_label += ' de ' + format_number( don_input ) + ' €';
      btn_href += '&amount=' + don_input.split(' ').join('') + '00';
    }
    $('#btn-don-IFI').html(btn_label);
    $('#btn-don-IFI').attr('href', btn_href);
  }


  function calculate_IR() {
    var income    = $('#income-IR').val().replace(/\s/g,'');
    var don_maxi  = 0;
    var don_input = $('#don-input-IR').val().replace(/\s/g,'');
    var don_after = 0;

    // Revenu net fiscal 2019 -> Montant maxi de dons défiscalisables
    if (income) {
      don_maxi = Math.round( income * 0.2 );      // 20% de revenu net fiscal 2019
      $('#don-maxi-IR').val( format_number( don_maxi ) );
    }

    // Don saisi -> Cout réel du don aprés déduction des 66%
    if (don_input) {
      don_after = Math.round( don_input * 0.34 );    // 66% du don saisi
      if (don_after < 0) don_after = 0;
      $('#don-after-IR').val( format_number( don_after ) );
    }

    // update btn label and url with don amount
    var btn_label = 'Je fais un don IR';
    var btn_href = $('#btn-don-IR').data('base-url');    // get href base in data attribute
    if (don_input && don_input > 0) {
      btn_label += ' de ' + format_number( don_input ) + ' €';
      btn_href += '&amount=' + don_input.split(' ').join('') + '00';
    }
    $('#btn-don-IR').html(btn_label);
    $('#btn-don-IR').attr('href', btn_href);
  }


  // format digits when typing ( 20000 -> 20 000 )
  function format_number(num) {
    return num.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1 ');
  }


});