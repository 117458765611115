// ==============================================

//        FORM CONTACT

// ==============================================


$(document).ready(function(){


  //        Cloud Cannon form
  // ==============================================


  // Helper function to get form data in the supported format
  function getFormDataString(formEl) {
    var formData = new FormData(formEl),
        data = [];

    for (var keyValue of formData) {
      data.push(encodeURIComponent(keyValue[0]) + "=" + encodeURIComponent(keyValue[1]));
    }

    return data.join("&");
  }

  // Fetch the form element
  var formEl   = document.getElementById("form-contact");
  var feedback = document.getElementById("form-contact__feedback");

  // Override the submit event
  formEl.addEventListener("submit", function (e) {
    e.preventDefault();
    // if (grecaptcha) {
    //   var recaptchaResponse = grecaptcha.getResponse();
    //   if (!recaptchaResponse) { // reCAPTCHA not clicked yet
    //     return false;
    //   }
    // }

    var request = new XMLHttpRequest();

    request.addEventListener("load", function () {
      console.log("request.status", request.status);
      if (request.status === 302 || request.status === 200) { // CloudCannon redirects on success
        // It worked
        feedback.innerHTML = 'Message envoyé.'
      } else {
        feedback.innerHTML = 'Erreur d\'envoi.'
      }
    });

    request.open(formEl.method, formEl.action);
    request.setRequestHeader("Content-Type", "application/x-www-form-urlencoded");
    console.log('data', getFormDataString(formEl));
    request.send(getFormDataString(formEl));
  });

});
